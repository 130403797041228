import React  from "react";
import Page from '../components/Page/Page';
import Stats from "../components/Stats/Stats";
import Individuals from '../components/Individuals/Individuals';
import Companies from "../components/Companies/Companies";
import Steps from '../components/Steps/Steps';
import Demo from '../components/Demo/Demo';
import VidThumb from '../components/VidThumb/VidThumb';
import '../scss/main.scss';
import { graphql } from "gatsby";



const IndexPage = ({data}) => {

  return (
    <>
    <Page {...data.allContentfulPages?.nodes[0]}>
      <Stats {...data.allContentfulStats?.nodes[0]}/>
      {data.allContentfulPages?.nodes?.[0]?.thumbVideo && <VidThumb {...data.allContentfulPages?.nodes?.[0]?.thumbVideo} classNames='center'/>}
      <Individuals/>
      <Companies/>
      {data?.allContentfulSteps?.edges?.[0]?.node && <Steps {...data?.allContentfulSteps?.edges?.[0].node}/>}
      <Demo/>
    </Page>
    </>
  )
}

export default IndexPage

export const query = graphql`
  query  {
    allContentfulPages(
      filter: {slug: {eq: "home"}}
    ) {
      nodes {
        description {
          raw
        }
        id
        slug
        title
        heroDescription {
          raw
        }
        headline
        image {
          title
          file {
            url
          }
        }
        metaTitle
        metaDesc
        metaImage {
          file {
            url
          }
        }
        buttons {
          link {
            link
          }
          target
          title
        }
        thumbVideo {
          title
          thumb {
            title
            file {
              url
            }
          }
          desc {
            raw
          }
          file {
            title
            file {
              url
            }
            description
          }
        }
      }
    }
    allContentfulStats(filter: {title: {}}) {
      nodes {
        title
        stat {
          description {
            raw
          }
          title
          source
        }
      }
    }
    allContentfulSteps(filter: {slug: {eq: "home"}}) {
      edges {
        node {
          slug
          title
          step {
            description {
              raw
            }
            title
            sub
          }
          image {
            file {
              url
            }
            title
          }
        }
      }
    }
  }`;