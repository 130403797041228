import React, { useState } from 'react';
import Image from '../Image/Image';
import PlayIcon from '../Icons/PlayIcon';
import { renderRichText } from "gatsby-source-contentful/rich-text";
import ReactPlayer from 'react-player'

interface Props {
  desc:any;
  title:string;
  file:{
    file: {
      url:string;
    };
  };
  thumb:{
    file: {
      file: {
        url:string;
      };
    };
    title:string;
  };
  classNames:string;
}

const VidThumb: React.FC<Props> = (props) => {

  const { title, desc, file, thumb, classNames } = props;
  const [ active, setActive ] = useState(false); 

  return (
    <div className={`vid-thumb ${classNames}`}>
      <div className='content'>
      {!active &&
        <div className='thumb-btn' onClick={() => setActive(true)}> 
        {thumb && <Image {...thumb}/>}
        <PlayIcon/>
        </div>
      }
      {active && 
        <div className='vid-player'>
          <ReactPlayer 
            playing
            controls
            className='react-player'
            url={file.file.url}
            width='100%'
            height='100%'
            />
        </div>   
      }
      </div>
      <div className='info'>
        {title && <h4> {title} </h4> } 
        {desc && renderRichText(desc) } 
      </div>
    </div>    
  )
}

export default VidThumb;


