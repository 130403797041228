import React, { useEffect, useState } from "react";

import { renderRichText } from "gatsby-source-contentful/rich-text"
import Image from '../Image/Image';
import { useInView } from 'react-intersection-observer';
interface Props {
    title:string;
    headline:string;
    description:any;
    image:{
      title:string;
      file: {
        url:string;
      };
    };
    backgroundColor:string;
    direction:string;
    slug?:string;
  }
  
const Block: React.FC<Props> = (props) => {
  const { direction, backgroundColor, image, title, description, slug=''} = props;
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0.3,
    initialInView:false
  });
  return (
    <section ref={ref} className={`block reveal ${inView ? 'active':''} ${direction}`} style={{backgroundColor:backgroundColor}} id={slug} >
        {image && <Image {...image} />}
        <div className="info">
          {title && <h3>{title}</h3>}
          {description && <div className='desc'>
            {renderRichText(description)}
          </div>}
        </div>
    </section>
  )
}

export default Block;
