import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Block from '../Block/Block';

const Individuals = () => {
  const data = useStaticQuery(
    graphql`
      query {
        contentfulBlock(slug: {eq: "leaders"}) {
          slug
          title
          description {
            raw
          }
          direction
          backgroundColor
          image {
            file {
              url
            }
            title
          }
        }
      }
    `);

  return (
    <>
    <Block {...data.contentfulBlock}/>
    </>
  )
}

export default Individuals;
