import React from "react";
import { useInView } from 'react-intersection-observer';
  
const Demo = () => {
  const { ref, inView } = useInView({
    threshold: 0.3,
  });
  return (
    <>
    <section ref={ref} className={`demo ${inView ? 'active':''}`}>
      <h3>Want to learn more?</h3> 
      <div className='btns'>
        <a href="https://calendly.com/sayslife/sayslife_demo" className='round-btn'>
          Request a Demo
        </a>
        or <a className='link' href='/contact'>Contact Us</a>
      </div> 
    </section>
    </>
  )
}

export default Demo;
