import React from "react";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { useInView } from 'react-intersection-observer';

interface Props {
  title:string;
  description:string;
  stat:any;
  children: React.ReactNode;
}
const Stats: React.FC<Props> = function Comp(props) {

  const { title, stat } = props;

  const { ref, inView } = useInView({
    threshold: 0.3,
  });

  return (
    <>
    <div ref={ref} className={`stats ${inView ? 'active':''}`}>
        {title && <div className="title">
            {title}
        </div>}
        <div className="stat-holder">
            {stat && stat.map((item, key) => (
                <div key={key} className="stat">
                    {item?.title && <h3 className="title">
                        {item?.title}
                    </h3>}
                    {item?.description && <h4 className="desc">
                        {renderRichText(item?.description)}
                    </h4>}
                    {item?.source && <p className="source">
                        {item?.source}
                    </p>}
                </div>
            ))}
        </div>
    </div>
    </>
  )
}

export default Stats
