import React from "react";
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Image from '../Image/Image';
import { useInView } from 'react-intersection-observer';

interface Props {
    title:string;
    image:{
      title:string;
      file: {
        url:string;
      };
    };
    step:any;
  }
  
const Block: React.FC<Props> = (props) => {
  
  const steps = props.step;
  
  const { ref, inView } = useInView({
    threshold: 0.3,
    initialInView:false
  });

  return (
    <>
    <section ref={ref} c className={`steps reveal ${inView ? 'active':''}`}>
      {props?.title && <h3>{props?.title}</h3>}
      <div className='content'>
        {props?.image && <div className="img">
          <Image {...props?.image} />
        </div>}
        <div className='info'>
        {steps && steps.map(( node ,key) => 
          <div className='step' key={key}>
            {node?.title && <div className='title'>
              {node?.title}
            </div>}
            {node?.sub && <div className='sub'>
              {node?.sub}
            </div>}
            {node?.description && <div className='desc'>
            {renderRichText(node?.description)}
            </div>}
          </div>
          )}
        </div>
      </div>
    </section>
    </>
  )
}

export default Block;
